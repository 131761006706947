import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";

import SuspenseLoader from "src/components/SuspenseLoader";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Status404 = Loader(lazy(() => import("src/content/pages/Status/Status404")));
const Status500 = Loader(lazy(() => import("src/content/pages/Status/Status500")));
const StatusComingSoon = Loader(lazy(() => import("src/content/pages/Status/ComingSoon")));
const StatusMaintenance = Loader(lazy(() => import("src/content/pages/Status/Maintenance")));
const Chat = Loader(lazy(() => import("src/content/chat/main")));
const EmailValidation = Loader(lazy(() => import("src/content/pages/Auth/EmailValidation/emailValidation")));
const EmailVerify = Loader(lazy(() => import("src/content/pages/Auth/EmailVerify/emailVerify")));
const SuccessLogin = Loader(lazy(() => import("src/content/pages/Auth/SuccessLogin/successLogin")));
// const QRCode = Loader(lazy(() => import("src/content/chat/components/qrCode")));

const baseRoutes = [
  {
    path: "/",
    element: <Navigate to="extended-sidebar/menu/home" replace />,
  },
  {
    path: "overview",
    element: <Navigate to="/" replace />,
  },
  {
    path: "*",
    element: <Status404 />,
  },
  {
    path: "status",
    children: [
      {
        path: "500",
        element: <Status500 />,
      },
      {
        path: "maintenance",
        element: <StatusMaintenance />,
      },
      {
        path: "coming-soon",
        element: <StatusComingSoon />,
      },
    ],
  },
  {
    path: "chat",
    element: <Chat />,
  },
  {
    path: "emailValidation/:email",
    element: <EmailValidation />,
  },
  {
    path: "verifyemail/*",
    element: <EmailVerify />,
  },
  {
    path: "desktop/*",
    element: <SuccessLogin />,
  },
  // {
  //   path: "qrCode",
  //   element: <QRCode />,
  // },
  {
    path: "*",
    element: <Status404 />,
  },
];

export default baseRoutes;
