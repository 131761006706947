import Authenticated from 'src/components/Authenticated'
import { Navigate } from 'react-router-dom'

import BoxedSidebarLayout from 'src/layouts/BoxedSidebarLayout'
import DocsLayout from 'src/layouts/DocsLayout'
import BaseLayout from 'src/layouts/BaseLayout'
import AccentHeaderLayout from 'src/layouts/AccentHeaderLayout'
import AccentSidebarLayout from 'src/layouts/AccentSidebarLayout'
import ExtendedSidebarLayout from 'src/layouts/ExtendedSidebarLayout'
import CollapsedSidebarLayout from 'src/layouts/CollapsedSidebarLayout'
import BottomNavigationLayout from 'src/layouts/BottomNavigationLayout'
import TopNavigationLayout from 'src/layouts/TopNavigationLayout'

// import dashboardsRoutes from './dashboards'
import menuRoutes from './menu'
import administrationRoutes from './administration'
// import blocksRoutes from './blocks'
// import applicationsRoutes from './applications'
import managementRoutes from './management'
import documentationRoutes from './documentation'
import accountRoutes from './account'
import baseRoutes from './base'

const router = [
  {
    path: 'account',
    children: accountRoutes,
  },
  {
    path: '',
    element: <BaseLayout />,
    children: baseRoutes,
  },

  // Documentation
  {
    path: 'docs',
    element: <DocsLayout />,
    children: documentationRoutes,
  },

  // Boxed Sidebar Layout
  {
    path: 'boxed-sidebar',
    element: (
      <Authenticated>
        <BoxedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="menu" replace />,
      },
      // {
      //   path: "dashboards",
      //   children: dashboardsRoutes,
      // },
      // {
      //   path: "blocks",
      //   children: blocksRoutes,
      // },
      // {
      //   path: "applications",
      //   children: applicationsRoutes,
      // },
      // {
      //   path: "management",
      //   children: managementRoutes,
      // },
      {
        path: 'menu',
        children: menuRoutes,
      },
    ],
    adm: [
      {
        path: 'administration',
        children: administrationRoutes,
      },
    ],
  },

  // Accent Sidebar Layout

  {
    path: 'accent-sidebar',
    element: (
      <Authenticated>
        <AccentSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="menu" replace />,
      },
      // {
      //   path: "dashboards",
      //   children: dashboardsRoutes,
      // },
      // {
      //   path: "blocks",
      //   children: blocksRoutes,
      // },
      // {
      //   path: "applications",
      //   children: applicationsRoutes,
      // },
      // {
      //   path: "management",
      //   children: managementRoutes,
      // },
      {
        path: 'menu',
        children: menuRoutes,
      },
      {
        path: 'administration',
        children: administrationRoutes,
      },
    ],
  },

  // Collapsed Sidebar Layout

  {
    path: 'collapsed-sidebar',
    element: (
      <Authenticated>
        <CollapsedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="menu" replace />,
      },
      // {
      //   path: "dashboards",
      //   children: dashboardsRoutes,
      // },
      // {
      //   path: "blocks",
      //   children: blocksRoutes,
      // },
      // {
      //   path: "applications",
      //   children: applicationsRoutes,
      // },
      // {
      //   path: "management",
      //   children: managementRoutes,
      // },
      {
        path: 'menu',
        children: menuRoutes,
      },
      {
        path: 'administration',
        children: administrationRoutes,
      },
    ],
  },

  // Bottom Navigation Layout

  {
    path: 'bottom-navigation',
    element: (
      <Authenticated>
        <BottomNavigationLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="menu" replace />,
      },
      // {
      //   path: "dashboards",
      //   children: dashboardsRoutes,
      // },
      // {
      //   path: "blocks",
      //   children: blocksRoutes,
      // },
      // {
      //   path: "applications",
      //   children: applicationsRoutes,
      // },
      // {
      //   path: "management",
      //   children: managementRoutes,
      // },
      {
        path: 'menu',
        children: menuRoutes,
      },
      {
        path: 'administration',
        children: administrationRoutes,
      },
    ],
  },

  // Top Navigation Layout

  {
    path: 'top-navigation',
    element: (
      <Authenticated>
        <TopNavigationLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="menu" replace />,
      },
      // {
      //   path: "dashboards",
      //   children: dashboardsRoutes,
      // },
      // {
      //   path: "blocks",
      //   children: blocksRoutes,
      // },
      // {
      //   path: "applications",
      //   children: applicationsRoutes,
      // },
      // {
      //   path: "management",
      //   children: managementRoutes,
      // },
      {
        path: 'menu',
        children: menuRoutes,
      },
      {
        path: 'administration',
        children: administrationRoutes,
      },
    ],
  },

  // Accent Header Layout

  {
    path: 'accent-header',
    element: (
      <Authenticated>
        <AccentHeaderLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="menu" replace />,
      },
      {
        path: 'menu',
        children: menuRoutes,
      },

      // {
      //   path: "dashboards",
      //   children: dashboardsRoutes,
      // },
      // {
      //   path: "blocks",
      //   children: blocksRoutes,
      // },
      // {
      //   path: "applications",
      //   children: applicationsRoutes,
      // },
      // {
      //   path: "management",
      //   children: managementRoutes,
      // },
    ],
  },

  // Extended Sidebar Layout
  ///////// Layout Usado /////////

  {
    path: 'extended-sidebar',
    element: (
      <Authenticated>
        <ExtendedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="menu" replace />,
      },
      {
        path: 'menu',
        children: menuRoutes,
      },
      {
        path: 'administration',
        children: administrationRoutes,
      },
      // {
      //   path: 'dashboards',
      //   children: dashboardsRoutes,
      // },
      // {
      //   path: "blocks",
      //   children: blocksRoutes,
      // },
      // {
      //   path: "applications",
      //   children: applicationsRoutes,
      // },
      {
        path: 'management',
        children: managementRoutes,
      },
    ],
  },
]

export default router
