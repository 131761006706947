import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import SuspenseLoader from 'src/components/SuspenseLoader'

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  )

const Home = Loader(lazy(() => import('src/content/home/home')))
const Details = Loader(lazy(() => import('src/content/details/details')))

const menuRoutes = [
  {
    path: '',
    element: <Navigate to="home" replace />,
  },
  {
    path: 'home',
    element: <Home />,
  },
  {
    path: 'details',
    element: <Details />,
  },
]

export default menuRoutes
