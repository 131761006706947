console.log('Enviroment: ', process.env.NODE_ENV)

// production
const production = {
  url: {
    API_URL: 'https://whatstv-api.herokuapp.com',
    API_WEBHOOK_BASE: 'https://whatstv-api.herokuapp.com/v1/',
  },
}

// development heroku
const sqa = {
  url: {
    API_URL: 'https://whatstv-api-develop.herokuapp.com',
    API_WEBHOOK_BASE: 'https://whatstv-api.herokuapp.com/v1/',
  },
}

// development local
const development = {
  url: {
    // Local
    // API_URL: "http://localhost:3000",
    // API_WEBHOOK_BASE: "http://localhost:3000/v1/",

    // Dev
    // API_URL: "https://whatstv-api-develop.herokuapp.com",
    // API_WEBHOOK_BASE: "https://whatstv-api-develop.herokuapp.com/v1/",

    // Prod
    API_URL: 'https://whatstv-api.herokuapp.com',
    API_WEBHOOK_BASE: 'https://whatstv-api.herokuapp.com/v1/',

    // Marcos
    // API_URL: 'https://f505-187-7-40-244.ngrok-free.app',
    // API_WEBHOOK_BASE: 'https://f505-187-7-40-244.ngrok-free.app/v1/',
  },
}

var choose = undefined
if (process.env.REACT_APP_ENVIROMENT === 'production') {
  choose = production
} else if (process.env.REACT_APP_ENVIROMENT === 'develop') {
  choose = sqa
} else {
  choose = development
}

export const config = choose
