import * as Yup from "yup";
import { Formik } from "formik";
import { Link as RouterLink } from "react-router-dom";
// import { apiPost } from 'src/configs/globalFunctions'

import {
  Box,
  Link,
  Button,
  FormHelperText,
  TextField,
  CircularProgress,
  Typography,
  // Stack,
  // Container,
  // Checkbox,
  // Typography,
  // FormControlLabel,
} from "@mui/material";
import useAuth from "src/hooks/useAuth";
import useRefMounted from "src/hooks/useRefMounted";
import { useTranslation } from "react-i18next";

const LoginJWT = () => {
  const { login } = useAuth();
  const isMountedRef = useRefMounted();
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        // email: 'demo@example.com',
        // password: 'TokyoPass1@',
        // email: 'eduardo@youngarts.com.br',
        // password: 'criacao',
        terms: true,
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email(t("O e-mail fornecido deve ser um endereço de e-mail válido")).max(255),
        // .required(t("O campo e-mail é obrigatório")),
        password: Yup.string().max(255).required(t("O campo de senha é obrigatório")),
        terms: Yup.boolean().oneOf([true], t("Você deve concordar com nossos termos e condições")),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await login(values.email, values.password);
          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error("teste", err.submitResult.data);
          if (isMountedRef.current) {
            let errosMsg = err.submitResult.data;

            //////Aquiiiiii
            setStatus({ success: false });
            setErrors(errosMsg);
            setSubmitting(false);
          }
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          {/* {errors.error ? (
            <Typography textAlign={"center"} bgcolor={"red"}>
              Usuário ou senha inválida!
            </Typography>
          ) : null} */}
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            margin="normal"
            autoFocus
            helperText={touched.email && errors.email}
            label={t("Email")}
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            margin="normal"
            helperText={touched.password && errors.password}
            label={t("Senha")}
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <Box alignItems="center" display={{ xs: "block", md: "flex" }} justifyContent="space-between">
            {/* <Box display={{ xs: 'block', md: 'flex' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.terms}
                    name="terms"
                    color="primary"
                    onChange={handleChange}
                  />
                }
                label={
                  <>
                    <Typography variant="body2">
                      {t('I accept the')}{' '}
                      <Link component="a" href="#">
                        {t('terms and conditions')}
                      </Link>
                      .
                    </Typography>
                  </>
                }
              />
            </Box> */}
            {/* <Link component={RouterLink} to="/account/recover-password">
              <b>{t("Esqueceu a senha?")}</b>
            </Link> */}
          </Box>

          {Boolean(touched.terms && errors.terms) && <FormHelperText error>{errors.terms}</FormHelperText>}

          <Button
            sx={{
              mt: 3,
            }}
            color="primary"
            startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmitting}
            type="submit"
            fullWidth
            size="large"
            variant="contained"
          >
            {t("Entrar")}
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default LoginJWT;
