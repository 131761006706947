import { Link as RouterLink } from 'react-router-dom'
import {
  Box,
  Card,
  Link,
  Tooltip,
  Typography,
  Container,
  styled,
  // Alert,
} from '@mui/material'
import { Helmet } from 'react-helmet-async'
import useAuth from 'src/hooks/useAuth'
import Auth0Login from '../LoginAuth0'
import FirebaseAuthLogin from '../LoginFirebaseAuth'
import JWTLogin from '../LoginJWT'
import AmplifyLogin from '../LoginAmplify'
import { useTranslation } from 'react-i18next'
// import Logo from 'src/components/Logo'
import Scrollbar from 'src/components/Scrollbar'

const icons = {
  Receba: '/static/images/logo/receba.png',
  Capture: '/static/images/logo/capture.png',
  Modere: '/static/images/logo/modere.png',
  Apresente: '/static/images/logo/apresente.png',
  Youon: '/static/images/logo/logoyouon.svg',
}

const Content = styled(Box)(
  () => `
    display: flex;
    flex: 1;
    width: 100%;    
`
)

const MainContent = styled(Box)(
  ({ theme }) => `
  @media (min-width: ${theme.breakpoints.values.md}px) {
    padding: 0 0 0 440px;
  }
  width: 100%;  
  display: flex;  
  align-items: center;  
`
)

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    background: ${theme.colors.alpha.white[100]};
    width: 440px;
`
)

const SidebarContent = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(6)};
`
)

const CardImg = styled(Card)(
  ({ theme }) => `
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid ${theme.colors.alpha.black[10]};
    transition: ${theme.transitions.create(['border'])};
    position: absolute;

    &:hover {
      border-color: ${theme.colors.primary.main};
    }
`
)

const TypographyH1 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(33)};
`
)

function LoginCover() {
  const { method } = useAuth()
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <title>Login Youon</title>
      </Helmet>
      <Content>
        <SidebarWrapper
          sx={{
            display: { xs: 'none', md: 'flex' },
          }}
        >
          <Scrollbar>
            <SidebarContent>
              {/* <Logo /> */}
              <img width={250} alt="Youon" src={icons['Youon']} />
              <Box mt={6}>
                <TypographyH1
                  variant="h1"
                  sx={{
                    mb: 7,
                  }}
                >
                  {t('Portal Youon')}
                </TypographyH1>
                <Box
                  sx={{
                    position: 'relative',
                    width: 300,
                    height: 120,
                  }}
                >
                  <Tooltip arrow placement="top" title="Receba">
                    <CardImg
                      sx={{
                        width: 80,
                        height: 80,
                        left: -20,
                        top: -40,
                      }}
                    >
                      <img width={50} alt="Auth0" src={icons['Receba']} />
                    </CardImg>
                  </Tooltip>
                  <Tooltip arrow placement="top" title="Capture">
                    <CardImg
                      sx={{
                        width: 90,
                        height: 90,
                        left: 70,
                      }}
                    >
                      <img width={70} alt="Firebase" src={icons['Capture']} />
                    </CardImg>
                  </Tooltip>
                  <Tooltip arrow placement="top" title="Modere">
                    <CardImg
                      sx={{
                        width: 100,
                        height: 100,
                        top: -30,
                        left: 170,
                      }}
                    >
                      <img
                        width={70}
                        alt="JSON Web Token"
                        src={icons['Modere']}
                      />
                    </CardImg>
                  </Tooltip>
                  <Tooltip arrow placement="top" title="Apresente">
                    <CardImg
                      sx={{
                        width: 90,
                        height: 90,
                        bottom: 0,
                        right: -55,
                      }}
                    >
                      <img width={70} alt="Amplify" src={icons['Apresente']} />
                    </CardImg>
                  </Tooltip>
                </Box>
                <Typography
                  variant="h3"
                  sx={{
                    my: 3,
                  }}
                >
                  {t('O jeito mais fácil de interagir com a sua audiência!')}
                </Typography>
                {/* <Typography
                  variant="subtitle1"
                  color="text.primary"
                  fontWeight="bold"
                >
                  {t('Want to switch auth methods?')}
                </Typography> */}
                <Typography variant="subtitle1">
                  {t(
                    'Com o Youon, sua equipe poderá publicar de forma extremamente rápida mensagens, imagens e vídeos recebidos das mídias sociais diretamente no seu estúdio de TV. Isso tudo de forma fácil e totalmente segura'
                  )}
                  .{' '}
                  {/* <Link component={RouterLink} to="/docs">
                    Read docs
                  </Link> */}
                </Typography>
              </Box>
            </SidebarContent>
          </Scrollbar>
        </SidebarWrapper>
        <MainContent>
          <Container
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              // flexDirection: 'column',
            }}
            maxWidth="sm"
          >
            <Card
              sx={{
                p: 4,
                my: 4,
              }}
            >
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1,
                  }}
                >
                  {t('Entrar')}
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{
                    mb: 3,
                  }}
                >
                  {t('Preencha os campos abaixo para entrar em sua conta.')}
                </Typography>
              </Box>
              {/* sendo usado JWTLogin */}
              {method === 'Auth0' && <Auth0Login />}
              {method === 'FirebaseAuth' && <FirebaseAuthLogin />}
              {method === 'JWT' && <JWTLogin />}
              {method === 'Amplify' && <AmplifyLogin />}
              <Box my={4}>
                <Typography
                  component="span"
                  variant="subtitle2"
                  color="text.primary"
                  fontWeight="bold"
                >
                  {t('Não tem uma conta ainda?')}
                </Typography>{' '}
                <Box display={{ xs: 'block', md: 'inline-block' }}>
                  <Link component={RouterLink} to="/account/register">
                    <b>Crie Aqui</b>
                  </Link>
                  {/* <Link component={RouterLink} to="/emailValidation">
                    <b>Email Validation </b>
                  </Link> */}
                </Box>
              </Box>
              {/* {method !== 'Auth0' && (
                <Tooltip
                  title={t('Used only for the live preview demonstration !')}
                >
                  <Alert severity="warning">
                    Use <b>demo@example.com</b> and password <b>TokyoPass1@</b>
                  </Alert>
                </Tooltip>
              )} */}
            </Card>
          </Container>
        </MainContent>
      </Content>
    </>
  )
}

export default LoginCover
