import { createContext, useEffect, useReducer } from 'react'
import { apiPost, encodeBase64 } from 'src/configs/globalFunctions'
import axios from 'src/utils/axios'
import PropTypes from 'prop-types'
// import { verify, JWT_SECRET } from "src/utils/jwt";
// import { accordionActionsClasses } from "@mui/material";
import Avatar from 'src/assets/avatar.png'

const initialAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
}

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken)
    localStorage.setItem('app-token', accessToken)
    // localStorage.setItem(
    //   "app-token",
    //   // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OTcsImlhdCI6MTcwMjM5NjczNywiZXhwIjoxNzMzOTMyNzM3fQ.ZU_0jCf5T4uvtCZH_HJvbviPr0QIWLGRpTG1iER4sG0' //Aparecida
    //   // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTc0LCJpYXQiOjE3MDEzNjc5NTgsImV4cCI6MTczMjkwMzk1OH0.WQ7uoEaRqHBip_wn7L_BRZAsJvokdt0nty6J6oJkUK0' //Twilio
    //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjk2ODgwMzM3LCJleHAiOjE3Mjg0MTYzMzd9.q6gqlgSJCWSi20BWrta_vb_BF3Lqlpm81SN04AGv0VY" //Bento
    //   // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OTgsImlhdCI6MTcwNjU1ODM3MCwiZXhwIjoxNzM4MDk0MzcwfQ.1laYuHS66XH8D2BW1W54clRvh5ROpPNuL9sWg5oIucU" //Pereira
    // );
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  } else {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('app-token')
    localStorage.removeItem('user')
    delete axios.defaults.headers.common.Authorization
  }
}

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    }
  },
  LOGIN: (state, action) => {
    const { user } = action.payload

    return {
      ...state,
      isAuthenticated: true,
      user,
    }
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload

    return {
      ...state,
      isAuthenticated: true,
      user,
    }
  },
}

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
})

export const AuthProvider = (props) => {
  const { children } = props
  const [state, dispatch] = useReducer(reducer, initialAuthState)

  useEffect(() => {
    const initialize = async () => {
      try {
        let accessToken = window.localStorage.getItem('accessToken')
        let user = JSON.parse(window.localStorage.getItem('user'))

        // if (accessToken && verify(accessToken, JWT_SECRET)) {
        if (accessToken) {
          setSession(accessToken)

          // console.log("inicio apiGet");
          // apiGet("/currentuser", {
          //   done: (resp) => {
          //     user = {
          //       ...resp.data.user,
          //        role: "admin",
          //      avatar: "https://cdn.discordapp.com/avatars/454640427505680385/095ebe76c699db67e253f5368e698901.webp?size=160",
          // };
          //   },
          // });
          // console.log("fim apiGet");

          // Old Code //
          // const response = await axios.get("/api/account/personal");
          // const { user } = response.data;
          // console.log('user1: ', user)

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          })
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          })
        }
      } catch (err) {
        //  console.log("erro de login: ", err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        })
      }
    }

    initialize()
  }, [])

  const login = async (email, password) => {
    let accessToken = ''
    let user = ''

    await apiPost('/authenticate/', {
      body: {
        email,
        password,
      },

      done: (resp) => {
        accessToken = resp.data.token

        user = {
          ...resp.data.user,
          role: 'admin',
          avatar: Avatar,
          // "https://cdn.discordapp.com/avatars/454640427505680385/095ebe76c699db67e253f5368e698901.webp?size=160",
        }

        // console.log("Login: ", user);

        if (accessToken) {
          localStorage.setItem('accessToken', accessToken)
          localStorage.setItem('app-token', accessToken)
          localStorage.setItem('user', JSON.stringify(user))
        }
      },

      error: (resp) => {
        console.log('erro', resp.data.error)
      },
    })

    setSession(accessToken)
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    })
  }

  const logout = async () => {
    setSession(null)
    dispatch({ type: 'LOGOUT' })
  }

  const register = async (email, name, password) => {
    // const response = await axios.post("/api/account/register", {
    //   email,
    //   name,
    //   password,
    // });
    // const { accessToken, user } = response.data;
    // let accessToken = "";
    // let user = "";
    console.log('email: ', encodeBase64(email))
    await apiPost('/users', {
      body: { email, name, password },
      done: (resp) => {
        // accessToken = resp.data.token;
        // user = {
        //   ...resp.data.user,
        //   role: "admin",
        //   avatar: "https://cdn1.iconfinder.com/data/icons/website-internet/48/website_-_male_user-512.png",
        // };
        window.location.href = '/emailValidation/' + encodeBase64(email)
        // console.log("email: ", encodeBase64(email));
      },
    })

    // window.localStorage.setItem("accessToken", accessToken);
    // dispatch({
    //   type: "REGISTER",
    //   payload: {
    //     user,
    //   },
    // });
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AuthContext
