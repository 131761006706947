import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import SuspenseLoader from 'src/components/SuspenseLoader'

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  )

const Script = Loader(lazy(() => import('src/content/script/script')))
const Clientes = Loader(lazy(() => import('src/content/clientes/clientes')))
const Plans = Loader(lazy(() => import('src/content/plans/plans')))
const Apps = Loader(lazy(() => import('src/content/apps/apps')))
const Reports = Loader(lazy(() => import('src/content/reports/reports')))

const administrationRoutes = [
  {
    path: '',
    element: <Navigate to="home" replace />,
  },
  {
    path: 'script',
    element: <Script />,
  },
  {
    path: 'clientes',
    element: <Clientes />,
  },
  {
    path: 'plans',
    element: <Plans />,
  },
  {
    path: 'apps',
    element: <Apps />,
  },
  {
    path: 'reports',
    element: <Reports />,
  },
]

export default administrationRoutes
