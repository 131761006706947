import { Box } from "@mui/material";
import LanguageSwitcher from "./LanguageSwitcher";
import ThemeSettings from "src/components/ThemeSettings/index";
// import HeaderNotifications from "./Notifications";
// import Chat from "./Chat";

function HeaderButtons() {
  return (
    <Box>
      {/* <Chat /> */}
      {/* <HeaderNotifications /> */}
      <LanguageSwitcher />
      <ThemeSettings />
    </Box>
  );
}

export default HeaderButtons;
